import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Thumbnail = styled.img`
  object-fit: cover;
  /* stylelint-disable-next-line */
  font-family: 'object-fit: cover'; /* object-fit-imagesのpolyfill対応 */

  @media (max-width: 1399px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    display: block;
    max-width: 295px;
    min-height: 192px;
    margin: 0 auto;
  }
`;

type Props = {
  imageUrl?: string;
  post: GatsbyTypes.MarkdownRemark;
};

const ArticleCardThumbnail: React.FC<Props> = ({ imageUrl, post }) => (
  <>
    {imageUrl && (
      <Link to={post.fields?.slug || ''}>
        <Thumbnail src={imageUrl} width={280} height={183} />
      </Link>
    )}
  </>
);

export default ArticleCardThumbnail;
