import React from 'react';
import styled from 'styled-components';

const Text = styled.h2`
  font-size: 30px;
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: 22px;
  }
`;

const ArchiveHeading: React.FC = ({ children }) => <Text>{children}</Text>;

export default ArchiveHeading;
