/**
 * タクソノミー系アーカイブのテンプレート(XDのcategory)
 */
import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '@/templates/Layout';
import ArchiveHeading from '@/atoms/ArchiveHeading';
import ArticleCardsList from '@/organisms/ArticleCardsList';
import Sidebar from '@/organisms/Sidebar';
import Pagination from '@/organisms/Pagination';
import Seo from '@/components/Seo';
import { CategoryPageContext } from '@/types/pageContext';
import { Content } from '@/templates/top';

const CategoryPage: React.FC<PageProps<GatsbyTypes.CategoryPageQuery>> = ({
  data,
  pageContext,
  location,
}) => {
  const posts = data.allMarkdownRemark.nodes as GatsbyTypes.MarkdownRemark[];
  const siteTags = data.allSiteTag.nodes as GatsbyTypes.SiteTag[];
  const siteCategories = data.allSiteCategory
    .nodes as GatsbyTypes.SiteCategory[];

  const { category, pageCount, currentPage } =
    pageContext as CategoryPageContext;

  const targetCategory = siteCategories.find(
    (siteCategory) => siteCategory.slug === category,
  );

  const categoryName = targetCategory?.title || category;
  const { pathname, href, origin } = location;

  return (
    <>
      <Seo
        url={href || `/category/${category}`}
        origin={origin}
        uniqueTitle={targetCategory?.title || category}
      />
      <Layout>
        <ArchiveHeading>{`${categoryName}の記事一覧`}</ArchiveHeading>
        <Content>
          <div>
            <ArticleCardsList posts={posts} siteTags={siteTags} />
            <Pagination
              slug={`category/${category}`}
              pageCount={pageCount}
              currentPage={currentPage}
            />
          </div>
          <Sidebar pathname={pathname} />
        </Content>
      </Layout>
    </>
  );
};

export const query = graphql`
  query CategoryPage($limit: Int, $skip: Int, $category: String) {
    allMarkdownRemark(
      filter: {
        fields: { itemType: { eq: "article" } }
        frontmatter: { categories: { eq: $category } }
      }
      sort: { order: DESC, fields: frontmatter___date }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        excerpt(truncate: true, format: PLAIN, pruneLength: 100)
        fields {
          slug
        }
        frontmatter {
          title
          tags
          date
          image_public_url
          image {
            publicURL
          }
        }
      }
    }
    allSiteTag {
      nodes {
        slug
        title
        is_pickup
      }
    }
    allSiteCategory {
      nodes {
        slug
        title
      }
    }
  }
`;

export default CategoryPage;
