import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Text = styled(Link)`
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.6;
  text-decoration: none;
`;

type Props = {
  href: string;
};

const ArticleCardTitle: React.FC<Props> = ({ href, children }) => (
  <h3>
    <Text to={href}>{children}</Text>
  </h3>
);

export default ArticleCardTitle;
