import React from 'react';
import styled from 'styled-components';
import ArticleCardTitle from '@/atoms/ArticleCardTitle';
import ArticleCardDescription from '@/atoms/ArticleCardDescription';
import ArticleCardDate from '@/atoms/ArticleCardDate';

const Wrapper = styled.div`
  padding-top: 15px;
`;

const TitleWrapper = styled.div`
  margin-top: 10px;
`;

const DescriptionWrapper = styled.div`
  margin-top: 15px;
`;

type Props = {
  post: GatsbyTypes.MarkdownRemark;
};

const ArticleCardInfo: React.FC<Props> = ({ post }) => (
  <Wrapper>
    <ArticleCardDate date={post.frontmatter?.date || ''} />
    <TitleWrapper>
      <ArticleCardTitle href={post.fields?.slug || ''}>
        {post.frontmatter?.title}
      </ArticleCardTitle>
    </TitleWrapper>
    <DescriptionWrapper>
      <ArticleCardDescription href={post.fields?.slug || ''}>
        {post.excerpt}
      </ArticleCardDescription>
    </DescriptionWrapper>
  </Wrapper>
);

export default ArticleCardInfo;
