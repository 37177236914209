import React from 'react';
import styled from 'styled-components';
import iconTag from '@/images/icon_tag.png';

const Text = styled.h2`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 180px;
  font-size: 20px;

  &::before {
    display: block;
    width: 34px;
    height: 34px;
    margin-right: 15px;
    content: '';
    background: center / contain no-repeat url(${iconTag});
  }
`;

const PickupTagHeading: React.FC = () => <Text>PICK UP タグ</Text>;

export default PickupTagHeading;
