import React from 'react';
import styled from 'styled-components';
import ArticleCardThumbnail from '@/atoms/ArticleCardThumbnail';
import ArticleCardInfo from '@/molecules/ArticleCardInfo';
import ArticleCardTagsList from '@/molecules/ArticleCardTagsList';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  height: 474px;
  padding: 20px;

  /* 段落ちを表現するためにmargin-rightを利用 */
  margin: 20px 20px 0 0;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);

  @media (max-width: 1399px) {
    width: calc(100 / 1400 * 320vw);
    height: auto;
    margin-right: calc(100 / 1400 * 20vw);
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 478px;
    margin-right: 0;
  }
`;

const TagsWrapper = styled.div`
  margin-top: 10px;
`;

type Props = {
  imageUrl?: string;
  post: GatsbyTypes.MarkdownRemark;
  tagsMap: Map<string, string>;
};

const ArticleCard: React.FC<Props> = ({ imageUrl, post, tagsMap }) => (
  <Card>
    <div>
      <ArticleCardThumbnail imageUrl={imageUrl} post={post} />
      <ArticleCardInfo post={post} />
    </div>
    <TagsWrapper>
      <ArticleCardTagsList tagsMap={tagsMap} post={post} />
    </TagsWrapper>
  </Card>
);

export default ArticleCard;
