import React from 'react';
import styled from 'styled-components';
import ArticleCard from '@/organisms/ArticleCard';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 1020px;

  /* 段落ちを表現するためにmargin-rightを利用 */
  margin: -20px -20px 0 0;

  @media (max-width: 1399px) {
    width: calc(100 / 1400 * 1020vw);
    margin-right: calc(100 / 1400 * -20vw);
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
`;

type Props = {
  posts: GatsbyTypes.MarkdownRemark[];
  siteTags: GatsbyTypes.SiteTag[];
};

const ArticleCardsList: React.FC<Props> = ({ posts, siteTags }) => (
  <Wrapper>
    {posts.map((post, index) => {
      const tagsMap: Map<string, string> = new Map();
      // biome-ignore lint/complexity/noForEach: Bimome初回導入のために、一時的に無視する
      siteTags.forEach((tag) => tagsMap.set(tag.slug || '', tag.title || ''));
      const imageUrl = (() => {
        if (post.frontmatter?.image_public_url)
          return post.frontmatter.image_public_url;
        if (post.frontmatter?.image?.publicURL)
          return post.frontmatter.image.publicURL;
        return undefined;
      })();

      return (
        <ArticleCard
          key={post.frontmatter?.title || index}
          imageUrl={imageUrl}
          post={post}
          tagsMap={tagsMap}
        />
      );
    })}
  </Wrapper>
);

export default ArticleCardsList;
