import React from 'react';
import styled from 'styled-components';
import formatDateText from '@/services/formatDateText';

const Text = styled.p`
  font-size: 12px;
  color: #666;
`;

type Props = {
  date: string;
};

const ArticleCardDate: React.FC<Props> = ({ date }) => (
  <Text>{formatDateText(date)}</Text>
);

export default ArticleCardDate;
