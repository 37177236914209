import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled(Link)<Props>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 18px;
  color: ${(props) => (props.disabled ? '#d0d0d0' : '#1b1b1b')};
  text-decoration: none;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  background: #fff;
  border-radius: 50%;

  @media (max-width: 767px) {
    width: 44px;
    height: 44px;
    font-size: 16px;
  }
`;

type Props = {
  to: string;
  disabled?: boolean;
};

const PaginationButton: React.FC<Props> = ({ to, disabled, children }) => (
  <Wrapper to={to} disabled={disabled}>
    {children}
  </Wrapper>
);

export default PaginationButton;
