import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Text = styled(Link)`
  display: block;
  font-size: 13px;
  line-height: 1.6;
  text-decoration: none;
`;

type Props = {
  href: string;
};

const ArticleCardDescription: React.FC<Props> = ({ href, children }) => (
  <p>
    <Text to={href}>{children}</Text>
  </p>
);

export default ArticleCardDescription;
