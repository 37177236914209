import React from 'react';
import styled from 'styled-components';
import PaginationButton from '@/atoms/PaginationButton';
import {
  getStartPagePc,
  getEndPagePc,
  getStartPageSp,
  getEndPageSp,
  getRangeArray,
} from '@/services/pagination';

const Wrapper = styled.nav`
  display: flex;
  justify-content: center;
  padding-right: 20px;
  margin-top: 50px;

  @media (max-width: 1399px) {
    padding-right: calc(100 / 1400 * 20vw);
  }

  @media (max-width: 767px) {
    padding-right: 0;
    margin-top: 20px;
  }
`;

const InnerPc = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 604px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const InnerSp = styled.div`
  display: none;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 767px) {
    display: flex;
  }
`;

const Pager = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 384px;

  @media (max-width: 767px) {
    justify-content: space-around;
    width: 100%;
  }
`;

const CurrentPage = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  background: #1b1b1b;
  border-radius: 50%;

  @media (max-width: 767px) {
    width: 44px;
    height: 44px;
  }
`;

const SkipPage = styled.p`
  width: 50px;
  text-align: center;

  @media (max-width: 767px) {
    width: auto;
  }
`;

type Props = {
  slug: string;
  pageCount: number;
  currentPage: number;
};

// FIXME: コードが汚い
const Pagination: React.FC<Props> = (props: Props) => {
  const { slug, pageCount, currentPage } = props;
  const path = slug ? `/${slug}/` : '/';
  const startPagePc = getStartPagePc(currentPage, pageCount);
  const endPagePc = getEndPagePc(currentPage, pageCount);
  const pageRangePc = getRangeArray(startPagePc, endPagePc);
  const startPageSp = getStartPageSp(currentPage, pageCount);
  const endPageSp = getEndPageSp(currentPage, pageCount);
  const pageRangeSp = getRangeArray(startPageSp, endPageSp);

  return (
    <Wrapper>
      <InnerPc>
        <PaginationButton
          to={startPagePc === 1 ? `${path}` : `${path}${startPagePc}/`}
          disabled={currentPage === 1}
        >
          {'<'}
        </PaginationButton>
        <Pager>
          {pageRangePc.map((page) =>
            page === currentPage ? (
              <CurrentPage key={`link-${page}`}>{page}</CurrentPage>
            ) : (
              <PaginationButton
                key={`link-${page}`}
                to={page === 1 ? `${path}` : `${path}${page}/`}
              >
                {page}
              </PaginationButton>
            ),
          )}
          {currentPage < pageCount - 3 && (
            <>
              <SkipPage>...</SkipPage>
              <PaginationButton to={`${path}${pageCount}/`}>
                {pageCount}
              </PaginationButton>
            </>
          )}
        </Pager>
        <PaginationButton
          to={`${path}${currentPage + 1}/`}
          disabled={currentPage === pageCount}
        >
          {'>'}
        </PaginationButton>
      </InnerPc>
      <InnerSp>
        <PaginationButton
          to={startPageSp === 1 ? `${path}` : `${path}${startPagePc}/`}
          disabled={currentPage === 1}
        >
          {'<'}
        </PaginationButton>
        <Pager>
          {pageRangeSp.map((page) =>
            page === currentPage ? (
              <CurrentPage key={`link-${page}`}>{page}</CurrentPage>
            ) : (
              <PaginationButton
                key={`link-${page}`}
                to={page === 1 ? `${path}` : `${path}${page}/`}
              >
                {page}
              </PaginationButton>
            ),
          )}
          {currentPage < pageCount - 2 && (
            <>
              <SkipPage>...</SkipPage>
              <PaginationButton to={`${path}${pageCount}/`}>
                {pageCount}
              </PaginationButton>
            </>
          )}
        </Pager>
        <PaginationButton
          to={`${path}${currentPage + 1}/`}
          disabled={currentPage === pageCount}
        >
          {'>'}
        </PaginationButton>
      </InnerSp>
    </Wrapper>
  );
};

export default Pagination;
