import React from 'react';
import styled from 'styled-components';
import PickupTagHeading from '@/atoms/PickupTagHeading';
import Tag from '@/atoms/Tag';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -15px 0 0 22px; /* タグ1行目の上部marginを相殺 */

  @media (max-width: 767px) {
    margin: 6px 0 0 -8px;
  }
`;

const Item = styled.li`
  display: block;
  margin: 15px 0 0 18px;

  @media (max-width: 767px) {
    margin: 10px 0 0 10px;
  }
`;

type Props = {
  siteTags: GatsbyTypes.SiteTag[];
};

const PickupTags: React.FC<Props> = ({ siteTags }) => (
  <Wrapper>
    <PickupTagHeading />
    <List>
      {siteTags
        .filter((tag) => tag.is_pickup)
        .map((tag) => {
          const slug = tag.slug || '';

          return (
            <Item key={tag.title}>
              <Tag href={`/tag/${slug}/`} label={tag.title || ''} />
            </Item>
          );
        })}
    </List>
  </Wrapper>
);

export default PickupTags;
