/**
 * トップページのテンプレート(XDのtop)
 */
import React from 'react';
import styled from 'styled-components';
import { graphql, PageProps } from 'gatsby';
import Layout from '@/templates/Layout/';
import PickupTags from '@/organisms/PickupTags';
import ArticleCardsList from '@/organisms/ArticleCardsList';
import Sidebar from '@/organisms/Sidebar';
import Pagination from '@/organisms/Pagination';
import Seo from '@/components/Seo';
import { ArchivePageContext } from '@/types/pageContext';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 30px;
  }
`;

const TopPage: React.FC<PageProps<GatsbyTypes.TopPageQuery>> = ({
  data,
  pageContext,
  location,
}) => {
  const posts = data.allMarkdownRemark.nodes as GatsbyTypes.MarkdownRemark[];
  const siteTags = data.allSiteTag.nodes as GatsbyTypes.SiteTag[];
  const { pageCount } = pageContext as ArchivePageContext;
  const { pathname } = location;

  return (
    <>
      <Seo
        uniqueOgImage=""
        url="/"
        origin={location.origin}
        uniqueTitle=""
        uniqueDescription=""
      />
      <Layout>
        <PickupTags siteTags={siteTags} />
        <Content>
          <div>
            <ArticleCardsList posts={posts} siteTags={siteTags} />
            <Pagination slug="" pageCount={pageCount} currentPage={1} />
          </div>
          <Sidebar pathname={pathname} />
        </Content>
      </Layout>
    </>
  );
};

export const query = graphql`
  query TopPage($limit: Int, $skip: Int) {
    allMarkdownRemark(
      filter: { fields: { itemType: { eq: "article" } } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        excerpt(truncate: true, format: PLAIN, pruneLength: 100)
        fields {
          slug
        }
        frontmatter {
          title
          tags
          date
          image_public_url
          image {
            publicURL
          }
        }
      }
    }
    allSiteTag {
      nodes {
        slug
        title
        is_pickup
      }
    }
  }
`;

export default TopPage;
