/**
 * ページネーションで最初に表示するページを取得する(PC用)
 * @param current 現在のページ
 * @param pages ページの総数
 * @returns 最初のページ
 */
export const getStartPagePc = (current: number, pages: number): number => {
  if (current === 1) return 1;
  if (current === pages) return current - 4 > 0 ? current - 4 : 1;
  if (current === pages - 1) return current - 3 > 0 ? current - 3 : 1;
  if (current === pages - 2) return current - 2 > 0 ? current - 2 : 1;
  return current - 1;
};

/**
 * ページネーションで最後に表示するページを取得する(PC用)
 * @param start 最初に表示するページ
 * @param pages ページの総数
 * @returns 最後のページ
 */
export const getEndPagePc = (current: number, pages: number): number => {
  if (current === 1 && pages > 3) return current + 3;
  if (current === pages - 3) return current + 3;
  if (current === pages) return current;
  if (current === pages - 1) return current + 1;
  return current + 2;
};

/**
 * ページネーションで最初に表示するページを取得する(SP用)
 * @param current 現在のページ
 * @param pages ページの総数
 * @returns 最初のページ
 */
export const getStartPageSp = (current: number, pages: number): number => {
  if (current === 1) return 1;
  if (current === pages) return current - 3 > 0 ? current - 3 : 1;
  if (current === pages - 1) return current - 2 > 0 ? current - 2 : 1;
  return current - 1;
};

/**
 * ページネーションで最後に表示するページを取得する(SP用)
 * @param start 最初に表示するページ
 * @param pages ページの総数
 * @returns 最後のページ
 */
export const getEndPageSp = (current: number, pages: number): number => {
  if (current === 1 && pages > 2) return current + 2;
  if (current === pages - 2) return current + 2;
  if (current === pages) return current;
  return current + 1;
};

/**
 * 数値の連番で配列を作成する
 * @param start 最初の数値
 * @param end 最後の数値
 * @returns 数値の配列
 */
export const getRangeArray = (start: number, end: number): number[] => {
  const r = [];
  for (let i = start; i <= end; i += 1) {
    r.push(i);
  }
  return r;
};
