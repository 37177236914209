/**
 * GraphQLから取得した投稿日を表示用に整形する
 * @param rawDate GraphQLから取得した投稿日の文字列
 * @returns yyyy年m月d日 の文字列 (一桁の月日は一桁で表示)
 */
const formatDateText = (rawDate: string): string => {
  const year = rawDate.substr(0, 4);
  const month = rawDate.substr(5, 2);
  const day = rawDate.substr(8, 2);

  // ゼロサプレス
  const cutMonth = /^0/.exec(month) ? month.slice(1) : month;
  const cutDay = /^0/.exec(day) ? day.slice(1) : day;

  return `${year}年${cutMonth}月${cutDay}日`;
};

export default formatDateText;
