import React from 'react';
import styled from 'styled-components';
import ArticleCardTag from '@/atoms/ArticleCardTag';
import iconTag from '@/images/icon_tag.png';

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &::before {
    position: relative;
    top: 2px;
    left: 0;
    display: block;
    width: 14px;
    height: 14px;
    content: '';
    background: center / contain no-repeat url(${iconTag});
  }
`;

const Item = styled.li`
  margin-left: 9px;
  white-space: nowrap;
`;

type Props = {
  tagsMap: Map<string, string>;
  post: GatsbyTypes.MarkdownRemark;
};

const ArticleCardTagsList: React.FC<Props> = ({ tagsMap, post }) => (
  <List>
    {post.frontmatter?.tags?.map((tag) => {
      const label = tag || '';
      return (
        <Item key={tagsMap.get(label)}>
          <ArticleCardTag href={`/tag/${label}/`}>
            {tagsMap.get(label)}
          </ArticleCardTag>
        </Item>
      );
    })}
  </List>
);

export default ArticleCardTagsList;
