import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Text = styled(Link)`
  font-size: 12px;
  color: #666;

  &::before {
    content: '#';
  }
`;

type Props = {
  href: string;
};

const ArticleCardTag: React.FC<Props> = ({ href, children }) => (
  <Text to={href}>{children}</Text>
);

export default ArticleCardTag;
